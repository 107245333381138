import styled from "styled-components";

import Calendar from "./components/Calendar";
import ApplicantSearch from "./components/ApplicantSearch";

// 인플루언서 화면
export default function RealEstateAgent() {
  return (
    <Container>
      <h2>임장 비서 신청 관리</h2>
      <div className="top">
        <Calendar />
      </div>
      <ApplicantSearch />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 84px;
  padding-bottom: 116px;
  width: 1440px;

  .top {
    display: flex;
  }

  h2 {
    margin-bottom: 17px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px; /* 150% */
    letter-spacing: -0.4px;
  }
`;
