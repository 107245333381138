import { useEffect, useState } from "react";

import styled from "styled-components";
import {
  borderColorObj,
  InputStatusEnum,
  InputTypeEnum,
} from "../BTypeInput.interfaces";

interface Props {
  inputType: InputTypeEnum;
  // label: string;
  value: any;
  placeholder: string;
  onChangeValue: (v: any) => void;
  handleFocus?: () => void;
  handleBlur?: () => void;
  disabled?: boolean;
  maxLength?: number;
  errorMessage?: string;
}

export default function BTypeTyping48({
  inputType = InputTypeEnum.Cash,
  value = null,
  placeholder,
  onChangeValue,
  handleFocus = () => {},
  handleBlur = () => {},
  disabled = false,
  maxLength,
  errorMessage,
}: Props) {
  const [isFocused, setIsFocused] = useState(false);
  const [status, setStatus] = useState<InputStatusEnum>(
    InputStatusEnum.Default
  );

  const onFocus = () => {
    setIsFocused(true);
    setStatus(value ? InputStatusEnum.Active : InputStatusEnum.Focus);
    handleFocus && handleFocus();
  };

  const onBlur = (e) => {
    e.preventDefault();
    setIsFocused(false);
    setStatus(value ? InputStatusEnum.Completion : InputStatusEnum.Default);
    handleBlur && handleBlur();
  };

  // console.log("🐙 ~ status:", status);
  useEffect(() => {
    // console.log("🐙 ~ useEffect ~ errorMessage:", errorMessage);
    if (errorMessage) {
      setStatus(InputStatusEnum.Error);
    } else {
      isFocused
        ? setStatus(InputStatusEnum.Active)
        : setStatus(InputStatusEnum.Default);
    }
  }, [errorMessage]);

  useEffect(() => {
    // console.log("🐙 ~ useEffect ~ errorMessage:", errorMessage);
    if (disabled) {
      setStatus(InputStatusEnum.Disabled);
    }
  }, [disabled]);

  return (
    <InputContainer
      className={[
        status === InputStatusEnum.Disabled ? "disabledBG" : "defaultBG",
      ].join(" ")}
      style={{
        borderColor: borderColorObj[status],
      }}
    >
      <Input
        className={[
          status === InputStatusEnum.Disabled
            ? ["disabledBG", "disabledInputColor"].join(" ")
            : ["defaultBG", "defaultInputColor"].join(" "),
        ].join(" ")}
        placeholder={placeholder}
        value={value}
        onChange={onChangeValue}
        // onChange={onChangeValue}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        style={{
          width: inputType === InputTypeEnum.OnlyText ? "100%" : "80%",
        }}
        maxLength={maxLength}
        autoComplete="none"
        autoCapitalize="off"
        autoCorrect="off"
        type={inputType === InputTypeEnum.Cash ? "text" : ""}
        pattern={inputType === InputTypeEnum.Cash ? "\\d*" : undefined}
        onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
          const target = event.target as HTMLInputElement;
          if (event.key === "Enter") {
            target.blur();
          }
        }}
        inputMode="none"
      />
      {/* <Unit
          className={[
            status === InputStatusEnum.Active ||
            status === InputStatusEnum.Focus ||
            status === InputStatusEnum.Completion ||
            (status === InputStatusEnum.Default && value)
              ? "visible"
              : "invisible",
          ].join(" ")}
        >
          {unitLabel}
        </Unit> */}
    </InputContainer>
  );
}

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 16px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  border-radius: 10px;

  &.disabledBG {
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  &.defaultBG {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  }
`;

const Input = styled.input`
  height: 24px;
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  border: none;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
  }

  &.disabledBG {
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  &.defaultBG {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  }

  .defaultInputColor {
    color: ${({ theme }) => theme.colors.primaryBlueGray};
  }

  .disabledInputColor {
    color: ${({ theme }) => theme.colors.blueGray500};
  }
`;

const Unit = styled.span`
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  color: var(--blue-gray-400);
  white-space: "nowrap";

  &.visible {
    visibility: visible;
  }

  &.invisible {
    visibility: hidden;
  }
`;

const Message = styled.div`
  &.message {
    font-size: 14px;
    color: var(--blue-gray-400);
    line-height: 22px;
    letter-spacing: -1px;
  }

  &.leftBottomMsg {
    position: absolute;
    left: 24px;
    bottom: 0px;
  }

  &.rightBottomMsg {
    position: absolute;
    right: 24px;
    bottom: 0px;
    text-align: right;
  }
`;
