import reset from "styled-reset";
import { createGlobalStyle } from "styled-components";
import { fonts } from "./Font";

const GlobalStyle = createGlobalStyle`

      ${reset}
      * {
        box-sizing: border-box;
      }
      ${fonts}
      body{
        font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        overscroll-behavior: contain; // 제스처로 뒤로가기 금지
      }

      a { text-decoration: none; }
      a:visited { text-decoration: none; }
      a:hover { text-decoration: none; }
      a:focus { text-decoration: none; }
      a:hover, a:active { text-decoration: none; }
      button, textarea {
        font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        cursor: pointer;
        border:none;
        background-color: transparent;
      }
      ::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent; 
        display: none;
      }

      p, h1, h2, h3, h4, h5, h6, div, span, input {  
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-smooth: never;
      }

      input {padding: 0}

      @media only screen and (max-width: 768px) {
        body {
          font-size: 12px;
        }
      }

      @media only screen and (max-width: 576px) {
        body {
          font-size: 10px;
        }
      }

     iframe
{
    display: none;
}
    `;

export default GlobalStyle;
