import { Title } from "@components/styles";
import ContentsTable from "@components/page/admin/report/contents/ContentsTable";
import styled from "styled-components";
import { useMenu } from "@features/useMenu";
import { useEffect, useState } from "react";
import { useGetContentsList } from "@hooks/admin";
import { Calendar } from "@components/core/calendar";
import { useNavigate } from "react-router-dom";

export default function Contents() {
  const navigate = useNavigate();
  const { setCurrentMenu } = useMenu();

  useEffect(() => {
    setCurrentMenu("contents");
  }, []);

  const [rangeDate, setRangeDate] = useState({ start: "", end: "" });
  const { data: contentsData } = useGetContentsList(rangeDate);

  const 제출페이지_이동 = () => {
    navigate("direct-add");
  };

  return (
    <Container>
      <Title>컨텐츠 업로드 관리</Title>
      <ControlWrapper>
        <UploadButton onClick={제출페이지_이동}>
          <p>레포트 직접 제출</p>
        </UploadButton>
        <Calendar setRangeDate={setRangeDate} text="기준: 제출날짜" />
      </ControlWrapper>
      <ContentsTable data={contentsData?.contents || []} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
`;

const UploadButton = styled.div`
  padding: 9px 16px;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: white;
  }
`;
