import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Main from "./pages/main/Main";
import Login from "./pages/login/Login";
import LoginReq from "@pages/login/LoginReq";
import PrivateRouter from "@components/layout/PrivateRouter";
import { AuthCallback } from "@pages/login/AuthCallback";
import {
  AccountingConfig,
  AccountingDetail,
  AccountingList,
  NoticeEdit,
  NoticeList,
  NoticeWrite,
} from "./pages/admin/report";
import {
  AddContent,
  Contents,
  EditContents,
} from "@pages/admin/report/contents";
import {
  AddSource,
  ApplicantList,
  CheckSource,
  ConsultationApplicant,
  ConsultationApproval,
  ConsultationReportList,
  ConsultationSourceList,
  ConsultingAdd,
  ConsultingEdit,
  ConsultingList,
  EditSource,
  PostPreQuestion,
} from "@pages/admin/consultation";
import { Accounting, Notice, Stat, Upload } from "@pages/influencer";
import {
  CompletedReportList,
  ReportList,
  ReuploadReport,
  SourceList,
  UploadReport,
  ViewComment,
  ViewSource,
} from "@pages/influencer/consultation";
import {
  ApplicantDetail,
  ExpertAdd,
  ExpertEdit,
  ImjangApplicant,
  ImjangExpert,
} from "@pages/admin/imjang";
import {
  PushList,
  PushChart,
  AddPushSchedule,
  EditPushSchedule,
  PushScheduleList,
  CohortList,
} from "@pages/admin/manage";

import DevMain from "@pages/dev/DevMain";
import AppVersion from "@pages/dev/AppVersion";
import BreakTime from "@pages/dev/BreakTime";
import WhiteList from "@pages/dev/WhiteList";
import Url from "@pages/dev/Url";
import NewsList from "@pages/admin/manage/news/NewsList";
import NewsAdd from "@pages/admin/manage/news/NewsAdd";
import IRList from "@pages/admin/manage/IR/IRList";
import IRDetail from "@pages/admin/manage/IR/IRDetail";
import NewsEdit from "@pages/admin/manage/news/NewsEdit";
import { UserGroupPage, UserListPage } from "@pages/user";
import JoinPage from "@pages/login/join/JoinPage";
import AlertProvider from "@components/layout/AlertProvider";
import UserEditPage from "@pages/user/userEdit/UserEditPage";
import Forbidden from "@pages/forbidden/Forbidden";
import PermissionLayout from "@components/router/PermissionLayout";
import RealEstateAgent from "@pages/admin/imjang/application/RealEstateAgent";
import StyledLayout from "@components/layout/StyledLayout";
import Coin from "@pages/admin/manage/coin/Coin";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AlertProvider />}>
          <Route element={<PrivateRouter authentication={false} />}>
            <Route path="/login" element={<Login />} />
            <Route path="/join" element={<JoinPage />} />
            <Route path="/login/req" element={<LoginReq />} />
            <Route path="/auth" element={<AuthCallback />} />
            <Route path="/403" element={<Forbidden />} />
          </Route>
          <Route element={<PrivateRouter authentication={true} />}>
            <Route element={<StyledLayout />}>
              <Route path="/" element={<Main />} />
              {/* 회원 및 멤버 관리 */}
              <Route
                path="/users"
                element={
                  <PermissionLayout
                    pageValue={"A01"}
                    style={{ padding: "48px 243px 60px" }}
                  />
                }
              >
                <Route index element={<UserListPage />} />
                <Route path=":id" element={<UserEditPage />} />
                <Route path="group" element={<UserGroupPage />} />
              </Route>

              {/* 기고문 관리 */}
              <Route
                path="/content"
                element={
                  <PermissionLayout
                    pageValue={"A02"}
                    style={{ paddingTop: 48, paddingBottom: 60 }}
                  />
                }
              >
                <Route index element={<Contents />} />
                <Route path="direct-add" element={<AddContent />} />
                <Route path=":id" element={<EditContents />} />
                <Route path="payment-form" element={<AccountingConfig />} />
                <Route path="payments" element={<AccountingList />} />
                <Route path="payment/:id" element={<AccountingDetail />} />
              </Route>

              {/* 상담소 관리 */}
              <Route
                path="/consultation"
                element={
                  <PermissionLayout
                    pageValue={"A03"}
                    style={{ paddingTop: 48, paddingBottom: 60 }}
                  />
                }
              >
                <Route path="applications" element={<ApplicantList />} />
                <Route
                  path="application/:id"
                  element={<ConsultationApplicant />}
                />
                <Route
                  path="application/:id/send"
                  element={<PostPreQuestion />}
                />
                <Route path="consultings" element={<ConsultingList />} />
                <Route path="consulting/add" element={<ConsultingAdd />} />
                <Route path="consulting/:id" element={<ConsultingEdit />} />
                <Route path="reports" element={<ConsultationReportList />} />
                <Route path="report/:id" element={<ConsultationApproval />} />
                <Route path="reference" element={<ConsultationSourceList />} />
                <Route path="reference/add" element={<AddSource />} />
                <Route path="reference/edit/:id" element={<EditSource />} />
                <Route path="reference/:id" element={<CheckSource />} />
              </Route>

              {/* 임장 비서 관리 */}
              <Route
                path="/imjang"
                element={<PermissionLayout pageValue={"A04"} />}
              >
                <Route path="applicants" element={<ImjangApplicant />} />
                <Route path="applicant/:id" element={<ApplicantDetail />} />
                <Route path="experts" element={<ImjangExpert />} />
                <Route path="expert/:id" element={<ExpertEdit />} />
                <Route path="expert/add" element={<ExpertAdd />} />
              </Route>

              {/* 운영 관리 */}
              <Route
                path="/manage"
                element={
                  <PermissionLayout
                    pageValue={"A05"}
                    style={{ paddingTop: 48, paddingBottom: 60 }}
                  />
                }
              >
                <Route path="notification/log" element={<PushList />} />
                <Route path="notification/chart" element={<PushChart />} />
                <Route path="push/group" element={<CohortList />} />
                <Route path="push" element={<PushScheduleList />} />
                <Route path="push/add" element={<AddPushSchedule />} />
                <Route path="push/:id" element={<EditPushSchedule />} />
                <Route path="ir" element={<IRList />} />
                <Route path="ir/:id" element={<IRDetail />} />
                <Route path="news" element={<NewsList />} />
                <Route path="news/add" element={<NewsAdd />} />
                <Route path="news/:id" element={<NewsEdit />} />
                <Route path="coin" element={<Coin />} />
              </Route>

              {/* 공지사항 관리 */}
              <Route
                path="/notice"
                element={
                  <PermissionLayout
                    pageValue={"A06"}
                    style={{ paddingTop: 48 }}
                  />
                }
              >
                <Route path="config" element={<NoticeList />} />
                <Route path="add" element={<NoticeWrite />} />
                <Route path=":id" element={<NoticeEdit />} />
              </Route>

              {/* 인플루언서 기고문 관리 */}
              <Route
                path="content"
                element={
                  <PermissionLayout
                    pageValue={"B01"}
                    style={{ paddingTop: 48 }}
                  />
                }
              >
                <Route path="upload" element={<Upload />} />
                <Route path="stat" element={<Stat />} />
                <Route path="accounting" element={<Accounting />} />
              </Route>

              {/* 내 상담소 */}
              <Route
                path="consultation"
                element={
                  <PermissionLayout
                    pageValue={"B02"}
                    style={{ paddingTop: 48 }}
                  />
                }
              >
                <Route path="report" element={<ReportList />} />
                <Route path="report/upload" element={<UploadReport />} />
                <Route path="report/upload/:id" element={<ReuploadReport />} />
                <Route
                  path="report/complete"
                  element={<CompletedReportList />}
                />
                <Route path="approval/:id" element={<ViewComment />} />
                <Route path="source" element={<SourceList />} />
                <Route path="source/:id" element={<ViewSource />} />
              </Route>

              {/* 임장 고객 관리*/}
              <Route
                path="/visit"
                element={<PermissionLayout pageValue={"B03"} />}
              >
                <Route index element={<RealEstateAgent />} />
                <Route path=":id" element={<ApplicantDetail />} />
              </Route>

              {/* 공지사항*/}
              <Route
                path="notification"
                element={
                  <PermissionLayout
                    pageValue={"B04"}
                    style={{ paddingTop: 48 }}
                  />
                }
              >
                <Route index element={<Notice />} />
              </Route>
            </Route>
          </Route>
        </Route>
        {/* <Route element={<DevPrivateRouter authentication={false} />}>
          <Route element={<DevLayout />}>
            <Route path="/dev" element={<DevMain />} />
          </Route>
        </Route>
        <Route element={<DevPrivateRouter authentication />}>
          <Route element={<DevLayout />}>
            <Route path="/dev/version" element={<AppVersion />} />
            <Route path="/dev/version/:id" element={<BreakTime />} />
            <Route path="/dev/url" element={<Url />} />
            <Route path="/dev/white-list" element={<WhiteList />} />
          </Route>
        </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
