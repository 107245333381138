import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import ConsultingInput from "@components/page/admin/consultation/consulting/ConsultingInput";
import Review from "@components/page/admin/consultation/consulting/detail/Review";
import { Title } from "@components/styles";
import { useConsultantInfo } from "@features/useConsultantInfo";
import { useMenu } from "@features/useMenu";
import {
  useEditConsultingInfo,
  useGetConsultingDetailInfo,
} from "@hooks/consultation";
import { uploadImageToS3ReturnResult } from "@utils/file";

import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

export default function ConsultingEdit() {
  const navigate = useNavigate();
  const params = useParams();

  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();
  const { setCurrentMenu } = useMenu();

  const consultingId = params.id;
  const [searchParams] = useSearchParams();
  const dbVersion = searchParams.get("db");
  const { data } = useGetConsultingDetailInfo({
    consultingId,
    dbVersion,
  });
  const { mutate, isLoading } = useEditConsultingInfo();
  const [image, setImage] = useState({
    profileImgUrl: { presignedUrl: null, data: null },
    mainImgUrl: { presignedUrl: null, data: null },
    introImgUrl: { presignedUrl: null, data: null },
    frequentVisitImgUrl: { presignedUrl: null, data: null },
    detailImgUrl: { presignedUrl: null, data: null },
    spotlightImgUrl: { presignedUrl: null, data: null },
    consultingImgUrl: { presignedUrl: null, data: null },
  });

  useEffect(() => {
    setCurrentMenu("consulting");
  }, []);

  useEffect(() => {
    if (data) {
      setGlobalConsultantInfo({
        ...data,
        reviews: data.reviews.reviews,
        influencer: data.influencer.id,
      });
    }
  }, [data]);

  const handleSave = async () => {
    const transformedArray = Object.entries(image).map(([key, value]) => ({
      name: key,
      ...value,
    }));
    const imgObj = { spotlightImgUrl: null, consultingImgUrl: null };
    const uploadPromises = transformedArray.map(async (el) => {
      // 이미지 변경이 있었다면 presigned 로 업로드 후 파일 이름 보내기
      if (image[el.name].data !== globalConsultantInfo[el.name]) {
        console.log("변경", el.name);
        const result = await uploadImageToS3ReturnResult({
          name: `${globalConsultantInfo.influencer}-${el.name}.png`,
          presignedUrl: el.presignedUrl,
          file: el.data,
        });
        // imgObj[el.name] = result;
        // spotlightImgUrl과 consultingImgUrl 예외처리가 힘들어서 그냥 사진이 있든없든 전부 파일이름 보냄.
        imgObj[el.name] = `${globalConsultantInfo.influencer}-${el.name}.png`;
      } else {
        console.log("변경 안함", el.name);
        // 이미지 변경이 없었다면 그냥 파일 이름으로 보내기
        imgObj[el.name] = `${globalConsultantInfo.influencer}-${el.name}.png`;
      }
    });

    await Promise.all(uploadPromises);
    console.log(imgObj);

    const obj = {
      ...globalConsultantInfo,
      ...imgObj,
      exposures: [
        {
          title: "spotlight",
          state: globalConsultantInfo.exposures.find(
            (item) => item.title === "spotlight"
          ).state,
          imgUrl: imgObj.spotlightImgUrl,
        },
        {
          title: "expertAdvice",
          state: globalConsultantInfo.exposures.find(
            (item) => item.title === "expertAdvice"
          ).state,
          imgUrl: imgObj.consultingImgUrl,
        },
      ],
    };

    console.log("🐙 ~ handleSave ~ obj: 통신 바디", obj);
    mutate(
      { body: obj, id: consultingId },
      {
        onSuccess: (res) => {
          if (window.confirm("등록 완료. 컨설팅 목록 화면으로 돌아갑니다.")) {
            navigate(-1);
          }
        },
        onError: (res: any) => {
          if (res) alert(res.response.data.message);
        },
      }
    );
  };

  if (!data) return;

  return (
    <Container>
      <Title>{data.influencer.name}의 컨설팅 관리</Title>
      <ConsultingInput
        handleSave={handleSave}
        data={data}
        image={image}
        setImage={setImage}
      />
      {isLoading ? <AbsoluteFillLoading /> : null}
    </Container>
  );
}

const Container = styled.div``;
