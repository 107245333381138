/* eslint-disable no-restricted-globals */
import { Button, ButtonWrapper } from "@components/styles";
import NoticeTable from "@components/page/admin/report/notice/NoticeTable";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDeleteNotice, useGetNoticeList } from "@hooks/admin";
import PlusIcon from "@assets/icon/PlusIcon";
import MinusIcon from "@assets/icon/MinusIcon";

export default function NoticeListContent() {
  const navigate = useNavigate();
  const goAddNotice = () => {
    navigate("/notice/add");
  };

  const [selectedRow, setSelectedRow] = useState();

  const { data } = useGetNoticeList();
  const { mutate: deleteNotice } = useDeleteNotice();

  const onDeleteUser = () => {
    if (!selectedRow) {
      alert("삭제할 공지를 선택해주세요.");
    } else {
      if (confirm("선택된 공지를 삭제합니다.")) {
        deleteNotice({ id: selectedRow });
      }
    }
  };

  return (
    <Container>
      <ButtonWrapper>
        <Button onClick={goAddNotice}>
          <PlusIcon />
          <p>공지 추가</p>
        </Button>
        <Button onClick={onDeleteUser}>
          <MinusIcon />
          <p>공지 삭제</p>
        </Button>
      </ButtonWrapper>
      <NoticeTable
        data={data?.announcements || []}
        setSelectedRow={setSelectedRow}
      />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;
