import { HTMLProps, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";
import cn from "classnames";
import Pagination from "@components/core/paging/Pagination";
import "@components/table.css";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import Empty from "@components/common/Empty";
import { useMenu } from "@features/useMenu";
import { CheckBoxEnum, SquareCheckBox20 } from "@components/core/icon/checkBox";

export default function NewsTable({ data, setSelectedRow }) {
  const navigate = useNavigate();
  const { globalThemeMode } = useMenu();

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("checkBox", {
        cell: ({ row, column }) => {
          return (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                onChange: row.getToggleSelectedHandler(),
                setSelectedRow: setSelectedRow,
                newsId: row.original["id"],
                key: row.original["id"],
              }}
            />
          );
        },
        header: "",
        maxSize: 5,
      }),
      columnHelper.accessor("number", {
        cell: (info) => data.length - info.row.index,
        header: "순번",
        maxSize: 5,
      }),
      columnHelper.accessor("title", {
        cell: ({ row }) => {
          return (
            <Title title={row.original["title"]}>{row.original["title"]}</Title>
          );
        },
        header: "기사 제목",
        maxSize: 45,
      }),
      columnHelper.accessor("url", {
        cell: ({ getValue }) => {
          return (
            <StyledLink href={`${getValue()}`} target="_blank">
              이동
            </StyledLink>
          );
        },
        header: "링크",
        maxSize: 10,
      }),
      columnHelper.accessor("releasedAt", {
        cell: ({ getValue }) => {
          return convertToKoreanDate(getValue());
        },
        header: "기사발행일",
        maxSize: 10,
      }),
      columnHelper.accessor("edit", {
        cell: ({ row }) => {
          const onClick = () => navigate(row.original["id"]);
          return <EditContents onClick={onClick}>정보수정</EditContents>;
        },
        header: "-",
        maxSize: 10,
      }),
    ];
  }, [data]);

  const table = useReactTable({
    data,
    columns,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  useEffect(() => {
    table.reset();
  }, [data]);

  return (
    <Container>
      <table className="contentsTable">
        <thead className={cn("contentsThead")}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn("contentsHeader")}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={cn("contentsData", {
                    darkContentsData: globalThemeMode === "dark",
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="컨텐츠가 없어요" />
      <Pagination table={table} />
    </Container>
  );
}

function IndeterminateCheckbox({
  className = "",
  onChange,
  setSelectedRow,
  newsId,
  ...rest
}: {
  setSelectedRow?: React.Dispatch<React.SetStateAction<string>>;
  newsId?: string;
} & HTMLProps<HTMLInputElement>) {
  const onClick = (e) => {
    onChange(e);
    setSelectedRow(newsId);
  };

  return (
    <div onClick={onClick}>
      <SquareCheckBox20
        status={rest.checked ? CheckBoxEnum.Activate : CheckBoxEnum.Default}
      />
    </div>
  );
}

const Container = styled.div``;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primaryBlue100};
`;

const EditContents = styled.p`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;
`;

const Title = styled.h2`
  margin-left: 20px;
  max-width: 600px;
  min-width: 600px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
