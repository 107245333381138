import {
  createColumnHelper,
  flexRender,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "@components/table.css";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "@components/core/paging/Pagination";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";

import {
  ApplicantInfo,
  ConsultationInfo,
  ConsultationStatusEnum,
} from "@hooks/consultation/Consulting.interfaces";
import StatusChip from "@components/common/StatusChip";
import Empty from "@components/common/Empty";

const approvalState = { pending: "대기", approve: "승인", reject: "반려" };

export default function CompletedReportTable({ data }) {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("state", {
        cell: ({ getValue, row }) => {
          const original = row.original as ApplicantInfo;
          return (
            <Center>
              <StatusChip status={getValue()} />
            </Center>
          );
        },
        header: "진행상태",
        maxSize: 10,
      }),
      columnHelper.accessor("title", {
        cell: ({ row }) => {
          const original = row.original as ApplicantInfo;
          const approvalState = original.report.approvalState;
          const goToDetail = () => {
            if (approvalState !== "pending") {
              navigate(`/consultation/approval/${original.applicantId}`);
            } else {
              window.open(original.report.notionUrl);
            }
          };
          return (
            <p
              style={{ marginLeft: 20, textAlign: "left", cursor: "pointer" }}
              onClick={goToDetail}
            >
              {original.report.title}
            </p>
          );
        },
        header: "제목",
        maxSize: 45,
      }),
      columnHelper.accessor("createdAt", {
        cell: ({ row }) => {
          const original = row.original as ApplicantInfo;
          return convertToKoreanDate(original.report.updatedAt);
        },
        header: "제출 날짜",
        maxSize: 15,
      }),
      columnHelper.accessor("createdAt", {
        cell: ({ row }) => {
          const original = row.original as ApplicantInfo;
          return convertToKoreanDate(original.report.approvedAt);
        },
        header: "승인 날짜",
        maxSize: 15,
      }),
      columnHelper.accessor("approvalState", {
        cell: ({ row }) => {
          const original = row.original as ApplicantInfo;
          return approvalState[original.report.approvalState];
        },
        header: "승인상태",
        maxSize: 15,
      }),
    ];
  }, []);

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const state = (row.original as ConsultationInfo).state;
            const disabled =
              state === ConsultationStatusEnum.상담취소 ||
              state === ConsultationStatusEnum.상담완료;
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="contentsData"
                    style={{
                      padding: "12px 0",
                      color: disabled ? "#B2B7BC" : "#4D5664",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Empty data={data} title="완료한 보고서가 없어요." />
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div``;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
