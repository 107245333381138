import ImjangExpertTable from "@components/page/admin/imjang/imjangExpert/ImjangExpertTable";
import { Button, ButtonWrapper, Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useEffect, useState } from "react";
import addIcon from "@assets/icon/addUser.png";
import deleteIcon from "@assets/icon/deleteUser.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDeleteImjangAgents, useGetImjangAgentList } from "@hooks/imjang";

export default function ImjangExpert() {
  const navigate = useNavigate();
  const { setCurrentMenu } = useMenu();
  const { data } = useGetImjangAgentList();
  const { mutate } = useDeleteImjangAgents();

  const [selectedRowArr, setSelectedRowArr] = useState([]);

  useEffect(() => {
    setCurrentMenu("imjangExpert");
  }, []);

  const goAddExpert = () => {
    navigate("/imjang/expert/add");
  };

  const deleteAgent = () => {
    if (selectedRowArr.length > 0) {
      if (window.confirm("선택한 중개사를 삭제할까요?")) {
        mutate(
          { agentIds: selectedRowArr },
          {
            onSuccess: (res) => {
              alert("삭제 완료");
              setSelectedRowArr([]);
            },
          }
        );
      }
    } else {
      alert("삭제할 중개사를 선택해주세요.");
    }
  };

  return (
    <Container>
      <Title>임장 비서 중개사 관리</Title>
      <ButtonWrapper>
        <Button onClick={goAddExpert}>
          <img src={addIcon} alt="plusIcon" style={{ width: 32, height: 32 }} />
          <p style={{ marginLeft: 0 }}>회원 추가</p>
        </Button>
        <Button onClick={deleteAgent}>
          <img
            src={deleteIcon}
            alt="deleteIcon"
            style={{ width: 32, height: 32 }}
          />
          <p style={{ marginLeft: 0 }}>회원 삭제</p>
        </Button>
      </ButtonWrapper>
      <ImjangExpertTable
        data={data?.agents || []}
        setSelectedRow={setSelectedRowArr}
      />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 48px;
  width: fit-content;
`;
