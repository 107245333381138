import { BTypeTyping48 } from "@components/core/BTypeInput";
import styled from "styled-components";
import { TextInputEnum } from "./BTypeText.interfaces";
import { useMemo, useState } from "react";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { debounce } from "@utils/debounce";

interface Props {
  inputType: InputTypeEnum;
  // label: string;
  value: any;
  placeholder: string;
  onChangeValue: (v: any) => void;
  handleFocus?: () => void;
  handleBlur?: () => void;
  disabled?: boolean;
  maxLength?: number;
  textType: TextInputEnum;
}

export default function BTypeText48({
  inputType,
  value,
  placeholder,
  onChangeValue,
  handleBlur,
  handleFocus,
  disabled,
  maxLength,
  textType = TextInputEnum.DEFAULT,
}: Props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleValidation = async (e) => {
    const text = e.target.value;

    switch (textType) {
      case TextInputEnum.DEFAULT:
        if (text.length >= maximumLength) {
          setErrorMessage("더이상 입력할 수 없어요");
          // return;
        }
        break;
      case TextInputEnum.NICKNAME:
        if (text.length >= 12) {
          setErrorMessage("12글자까지 입력할 수 있어요");
          // return;
        } else if (/[^a-zA-Z0-9가-힣\s]/g.test(text) && text !== null) {
          setErrorMessage("특수문자는 입력할 수 없어요");
        } else {
          setErrorMessage("");
        }
        break;
      case TextInputEnum.NAME:
        console.log("text", text);
        if (text.length >= 6) {
          setErrorMessage("이름은 6글자까지 입력할 수 있어요");
          // return;
        } else if (/[^가-힣]/g.test(text) && text !== null) {
          setErrorMessage("이름은 한글로만 입력할 수 있어요");
        } else {
          setErrorMessage("");
        }
        break;
      case TextInputEnum.PHONE_NUM:
        break;
      case TextInputEnum.EMAIL:
        const pattern = /^[a-zA-Z0-9@.]+$/;
        if (pattern.test(text) || !text) {
          setErrorMessage("");
        } else {
          setErrorMessage("이메일 형식이 올바르지 않아요");
        }
    }

    onChangeValue(e);
    // setErrorMessage('');
  };

  const onBlur = () => {
    setIsFocused(false);
    if (handleBlur) handleBlur();

    switch (textType) {
      case TextInputEnum.DEFAULT:
        setErrorMessage("");
        break;
      case TextInputEnum.PHONE_NUM:
        if (value.length < 10) {
          setErrorMessage("잘못된 형식입니다");
        } else {
          setErrorMessage("");
        }
        break;
      case TextInputEnum.EMAIL:
        const pattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (pattern.test(value)) {
          setErrorMessage("");
        } else {
          setErrorMessage("잘못된 이메일 형식입니다");
        }
        break;

      default:
        return;
    }
  };

  const maximumLength = useMemo(() => {
    if (maxLength) return maxLength;
    switch (textType) {
      case TextInputEnum.DEFAULT:
        return 100;
      case TextInputEnum.NAME:
        return 6;
      case TextInputEnum.PHONE_NUM:
        return 11;
      case TextInputEnum.EMAIL:
        return 30;
      default:
        return 100;
    }
  }, [textType]);

  return (
    <Container>
      <div className="inputWrapper" />
      <BTypeTyping48
        inputType={inputType}
        value={value}
        placeholder={placeholder}
        onChangeValue={handleValidation}
        handleBlur={onBlur}
        disabled={disabled}
        maxLength={maximumLength}
        errorMessage={errorMessage}
      />
      {errorMessage && (
        <Message>
          <span className="message">{errorMessage}</span>
        </Message>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 74px;
  width: 100%;
`;

const Message = styled.div`
  margin-top: 6px;
  height: 18px;

  .message {
    color: ${({ theme }) => theme.colors.red1000};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: -0.24px;
  }
`;
