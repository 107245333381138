import styled from "styled-components";
import cn from "classnames";
import { MouseIcon, RightArrowIcon } from "./TooltipIcon";

export default function FixedScrollTooltip() {
  return (
    <Container>
      <Center>
        <Tooltip>
          <p>Shift +</p>
          <span style={{ margin: "0 6px 0 4px" }}>
            <MouseIcon />
          </span>
          <p className="disappear">으로 좌우 스크롤 할 수 있어요</p>
          <span style={{ marginLeft: "auto" }}>
            <RightArrowIcon />
          </span>
        </Tooltip>
      </Center>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 40px;
  z-index: 100;
`;

const Center = styled.div`
  margin: 0 auto;
  width: 1440px;
`;

const Tooltip = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 56px;
  width: 141px;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  white-space: nowrap;
  cursor: pointer;

  transition: width 0.5s ease;

  &:hover {
    width: 334px;

    .disappear {
      opacity: 1;
      width: 181px;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 16px;
    font-weight: 500;
    line-height: 26px; /* 162.5% */
    letter-spacing: -0.32px;
    opacity: 1;
    transition: opacity 0.2s ease;
  }

  .disappear {
    width: 0;
    opacity: 0;
  }
`;
