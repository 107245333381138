import styled from "styled-components";

import NewApplication from "./components/NewApplication";
import Calendar from "./components/Calendar";
import ApplicantSearch from "./components/ApplicantSearch";

export default function ImjangApplicant() {
  return (
    <Container>
      <div className="top">
        <NewApplication />
        <Calendar />
      </div>
      <ApplicantSearch />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 84px;
  padding-bottom: 116px;
  width: 1440px;

  .top {
    display: flex;
  }
`;
