import { useImjang } from "@features/useImjang";
import { useGetImjangStatus, useImjangApplyList } from "@hooks/imjang";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function Search() {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState("");
  const [searchList, setSearchList] = useState([]);
  const { mutate } = useImjangApplyList();
  const { data } = useGetImjangStatus({ filter: "main" });
  const { setGlobalSearchFilter, globalImjangStatus } = useImjang();

  const debounceMutate = debounce((item) => {
    mutate(
      {
        filter: {
          statusList: data?.map((el) => el.value),
          applyUserName: item,
          madminId: globalImjangStatus.searchFilter.madminId,
        },
      },
      {
        onSuccess: (res) => {
          setSearchList(res.applyList);
        },
      }
    );
  }, 500);

  useEffect(() => {
    if (!value) {
      setSearchList([]);
      return;
    }
    debounceMutate(value);

    // Cleanup function to cancel debounce on unmount or value change
    return () => {
      debounceMutate.cancel();
    };
  }, [value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      // Call your specific function here
      setGlobalSearchFilter({ applyUserName: value });
      setIsFocused(false);
      console.log("Enter key pressed");
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Input
        placeholder="고객명 검색"
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setTimeout(() => setIsFocused(false), 300);
          setGlobalSearchFilter({ applyUserName: value });
        }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      {isFocused && <EsSearch searchList={searchList} data={data} />}
      <Icon />
    </div>
  );
}

const EsSearch = ({ searchList, data }) => {
  if (searchList.length === 0)
    return (
      <Empty>
        <span>검색어를 입력하세요</span>
      </Empty>
    );

  return (
    <Result>
      <div className="header">
        <p>{searchList.length}건의 검색 결과가 있습니다.</p>
        <div className="divider" />
      </div>
      {searchList.map((item, index) => {
        const selected = data?.find((el) => el.value === item.status);
        return (
          <ResultRow
            key={index}
            name={item.name}
            id={item.applyId}
            status={selected?.name || "미지정"}
          />
        );
      })}
    </Result>
  );
};

const ResultRow = ({ name, status, id }) => {
  const navigate = useNavigate();
  const goToDetail = () => {
    navigate(`/imjang/applicant/${id}`);
  };

  return (
    <Row onClick={goToDetail}>
      <div className="chip">
        <span>{status}</span>
      </div>
      <span>{name}</span>
    </Row>
  );
};

const Icon = () => {
  return (
    <div style={{ position: "absolute", left: 10, top: 8 }}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.562 6.55169C13.4946 5.47485 12.0682 4.8892 10.5568 4.8892C9.04548 4.8892 7.61912 5.4843 6.55172 6.55169C5.47487 7.61909 4.88921 9.04544 4.88921 10.5568C4.88921 12.0682 5.48431 13.4945 6.55172 14.5619C7.61912 15.6387 9.04548 16.2244 10.5568 16.2244C12.0682 16.2244 13.4946 15.6293 14.562 14.5619C15.6388 13.4945 16.2245 12.0682 16.2245 10.5568C16.2245 9.04544 15.6294 7.61909 14.562 6.55169ZM5.21038 5.21036C6.69341 3.73679 8.62041 3 10.5568 3V3.00945C12.4933 3.00945 14.4297 3.74623 15.9033 5.21981C18.6108 7.92729 18.831 12.1893 16.5639 15.1496L20.7104 19.2962C21.1009 19.6867 21.1009 20.3199 20.7104 20.7104C20.3199 21.1009 19.6867 21.1009 19.2962 20.7104L15.1482 16.5624C13.7947 17.5965 12.1791 18.1136 10.5568 18.1136C8.62041 18.1136 6.68396 17.3768 5.21038 15.9032C2.26321 12.9466 2.26321 8.16696 5.21038 5.21036Z"
          fill="#808690"
        />
      </svg>
    </div>
  );
};

const Input = styled.input`
  display: flex;
  width: 408px;
  height: 40px;
  padding: 8px 10px 8px 40px;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.white};
  justify-content: center;
  align-items: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.colors.blueGray800};

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
  }
  &:focus {
    outline: none;
  }
`;

const Empty = styled.div`
  position: absolute;
  top: 48px;
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 10;

  span {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.32px;
  }
`;

const Result = styled.div`
  position: absolute;
  top: 48px;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  background-color: ${({ theme }) => theme.colors.white};
  overflow: scroll;
  z-index: 20;

  .header {
    margin-bottom: 6px;
    padding: 2px 8px;

    p {
      color: ${({ theme }) => theme.colors.blueGray700};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.32px;
    }

    .divider {
      margin-top: 8px;
      height: 1px;
      width: 100%;
      background: ${({ theme }) => theme.colors.blueGrayA200};
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) =>
      theme.colors.blueGrayA100}; /* hover 시 배경색 변경 */
  }

  .chip {
    display: flex;
    margin-right: 6px;
    padding: 5px 6px;
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.blue100};
    justify-content: center;
    align-items: center;

    span {
      color: ${({ theme }) => theme.colors.blue1000};
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.24px;
    }
  }

  span {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -0.32px;
  }
`;
