export enum ImjangStatusEnum {
  "reception" = "reception",
  "waiting" = "waiting",
  "proceed" = "proceed",
  "cancel" = "cancel",
  "done" = "done",
}

export const tradeTypeObj = {
  sell: "매매",
  rent: "전/월세",
  none: "상관없음",
};

export type tradeType = keyof typeof tradeTypeObj;
export interface ImjangListInfo {
  applyDate: string;
  applyId: string;
  danjiNum: number;
  name: string;
  phoneNumber: string;
  state: string;
}

interface ContactInfo {
  name: string;
  phoneNumber: string;
  comments: string;
}

export interface DanjiInfo {
  id: string;
  단지코드: number;
  법정동코드: number;
  대표단지명: string;
  전체시도명: string;
  전체구군명: string;
  법정대표동: string;
  법정동주소: string;
  도로명주소: string;
  건물유형: string;
  어드민추가여부: boolean;
}

interface PyeongInfo {
  평형코드: number;
  평수: number;
  평타입: string;
  공급면적_제곱미터: number;
  전용면적_제곱미터: number;
}

export interface DanjiDetail {
  danjiCode: number;
  scaleCode: number;
  tradeType: tradeType;
  danjiInfo: DanjiInfo;
  pyeongInfo: PyeongInfo;
  maemulInfo: any;
}

export interface ScheduleList {
  meetingDate: string;
  meetingTime: string;
  meetingPlace: string;
  description: string;
  danjiCode: number;
  scaleCode: number;
  danjiName: string;
  pyeongType: string;
  tradeType: string;
  agentOfficeName: string;
  agentOfficeContact: string;
}

export interface ImjangApplicantDetail {
  applyId: string;
  state: string;
  commentNum: number;
  scheduleList: ScheduleList[];
  applyDate: string;
  contactInfo: ContactInfo;
  dateInfo: any[];
  danjiInfo: DanjiDetail[];
  reason: string;
  reward: string;
  isWebUser: boolean;
  isCancelled: boolean | null;
  cancelReason: string | null;
  isScheduled: boolean;
  isMyClient: boolean;
}

export interface ImjangApplyListReq {
  filter: {
    statusList?: string[];
    madminId?: string;
    applyUserName?: string;
  };
}

export interface ImjangApplyDetail {
  applyId: string;
  name: string;
  createdAt: string;
  phoneNumber: string;
  status: string;
  madminList: {
    madminId: string;
    name: string;
    profileColor: string;
  }[];
  isWebUser: boolean;
  isScrapped: boolean;
  isNew: boolean;
  isWarning: boolean;
  commentNum: number;
  isCancelled: boolean;
}

export interface ImjangApplyListRes {
  applyList: ImjangApplyDetail[];
}

export interface ImjangCancelReq {
  isCancelled: boolean;
  reason?: string;
}

export interface ImjangThread {
  threadList: {
    id: string;
    authorName: string;
    profileColor: string;
    text: string;
    files: { id: string; name: string; url: string }[];
  }[];
}

export interface ImjangThreadReq {
  text?: string;
  files?: {
    name: string;
    s3ObjectKey: string;
  }[];
}

export interface presignedBody {
  url: string;
  fields: {
    key: string;
    "x-amz-algorithm": string;
    "x-amz-credential": string;
    "x-amz-date": string;
    policy: string;
    "x-amz-signature": string;
  };
}

export interface TreadFilePresignedRes {
  presigned_url: presignedBody;
}
