import styled from "styled-components";
import Logo from "@assets/logo/Logo";
import { useEffect, useRef, useState } from "react";
import { Arrows18 } from "@components/core/icon/arrow";
import EmptyProfile28 from "@components/core/icon/profile/EmptyProfile28";
import SubMenu from "./SubMenu";
import UserInfo from "./UserInfo";
import { Link } from "react-router-dom";
import useClickOutside from "@hooks/useClickOutside";

export default function BigNav({ navList, userInfo }) {
  const mainHoverRef = useRef(false);
  const subHoverRef = useRef(false);
  const userInfoRef = useRef(null);
  const simpleUserInfoRef = useRef(null);
  const [isHover, setIsHover] = useState(false);
  const [hoverState, setHoverState] = useState(false); // ref 변경을 감지하기 위한 상태
  const [clickUserInfo, setClickUserInfo] = useState(false);

  useEffect(() => {
    if (mainHoverRef.current || subHoverRef.current) {
      setIsHover(true);
    } else {
      setIsHover(false);
    }
  }, [hoverState]); // hoverState가 변경될 때 useEffect 실행

  const handleMouseEnter = (ref) => {
    ref.current = true;
    setHoverState(!hoverState); // ref 변경 감지
  };

  const handleMouseLeave = (ref) => {
    ref.current = false;
    setHoverState(!hoverState); // ref 변경 감지
  };

  const 유저정보_클릭 = () => {
    setIsHover(false);
    setClickUserInfo((prev) => !prev);
  };

  // 다른 곳 클릭시 사라지게
  useClickOutside([userInfoRef, simpleUserInfoRef], () =>
    setClickUserInfo(false)
  );

  useEffect(() => {
    if (isHover) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isHover]);

  return (
    <>
      <Container>
        <MainNavContainer onMouseLeave={() => handleMouseLeave(mainHoverRef)}>
          <Top>
            <Link className="logo" to={"/"}>
              <Logo />
            </Link>
            <div
              className="userInfo"
              onClick={유저정보_클릭}
              ref={simpleUserInfoRef}
            >
              <EmptyProfile28 />
              <p className="group">{userInfo.user_group}</p>
              <p className="email">{userInfo.email}</p>
              <Arrows18 />
            </div>
          </Top>
          <Bottom onMouseEnter={() => handleMouseEnter(mainHoverRef)}>
            <Gap48List>
              {navList.map((el, idx) => (
                <MainMenu key={idx}>
                  <h1 className="title">{el.title}</h1>
                </MainMenu>
              ))}
            </Gap48List>
          </Bottom>
          {clickUserInfo && <UserInfo userInfo={userInfo} ref={userInfoRef} />}
        </MainNavContainer>
        <SubMenuContainer
          $isShow={isHover}
          onMouseEnter={() => handleMouseEnter(subHoverRef)}
          onMouseLeave={() => handleMouseLeave(subHoverRef)}
        >
          <Gap48List>
            {navList.map((navItem, idx) => {
              return (
                <SubMenu
                  data={navItem}
                  key={idx}
                  onClick={() => setIsHover(false)}
                />
              );
            })}
          </Gap48List>
        </SubMenuContainer>
      </Container>
      <Backdrop $isVisible={isHover} />
    </>
  );
}

// 화면 100vw의 너비를 커버하는 컨테이터
const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  /* height: fit-content; */

  background-color: ${({ theme }) => theme.colors.white};
  z-index: 999;
`;

// 1440px 크기의 실제 nav를 감싸는 컨테이너
const MainNavContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* padding: 0 60px; */
  width: 100%;
  max-width: 1440px;
  min-width: 1440px;

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 110px;
    left: -30%;
    right: 0;
    width: 200vw;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.blueGrayA200};
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;

  .logo {
    display: flex;
    align-items: center;
    height: 32px;
  }

  .userInfo {
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;

    .group {
      margin: 0 8px;
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }

    .email {
      margin-right: 2px;
      color: ${({ theme }) => theme.colors.blueGray500};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.28px;
    }
  }
`;

const Bottom = styled.div`
  padding-bottom: 8px;
  width: fit-content;
  min-height: 48px;
`;

const SubMenuContainer = styled.div<{ $isShow: boolean }>`
  margin: 0 auto;
  padding: 10px 0 20px;
  width: 100%;
  max-width: 1440px;
  min-width: 1440px;
  display: ${({ $isShow }) => ($isShow ? "flex" : "none")};
`;

const Gap48List = styled.div`
  display: flex;
  gap: 48px;
  width: fit-content;
`;

const MainMenu = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 40px;
  cursor: pointer;

  .title {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;

const Backdrop = styled.div<{ $isVisible: boolean }>`
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${({ $isVisible }) => ($isVisible ? "flex" : "none")};
`;
