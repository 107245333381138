import { Arrows18 } from "@components/core/icon/arrow";
import { ArrowDirectionEnum } from "@components/core/icon/arrow/Arrow.interfaces";
import useClickOutside from "@hooks/useClickOutside";
import { useMemo, useRef, useState } from "react";
import styled from "styled-components";

export enum dropDownTypeEnum {
  A = "A",
  B = "B",
}

interface Props {
  optionList: { title: string; value: string }[];
  type?: dropDownTypeEnum;
  selectedValue: string;
  onChangeValue: (v: string) => void;
  style?: React.CSSProperties;
  scrollHeight?: number;
}

export default function DropDown({
  optionList,
  selectedValue,
  onChangeValue,
  type = dropDownTypeEnum.A,
  style,
  scrollHeight,
}: Props) {
  const dropDownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (item: string) => {
    onChangeValue(item);
    setIsOpen(false);
  };

  const displayTitle = useMemo(() => {
    const find = optionList.find((item) => item.value === selectedValue);
    if (!find) return "";
    return find.title;
  }, [selectedValue, optionList]);

  useClickOutside([dropDownRef], () => setIsOpen(false));

  return (
    <div style={{ position: "relative", ...style }} ref={dropDownRef}>
      <Container
        onClick={handleClick}
        style={{ borderRadius: type === dropDownTypeEnum.A ? 24 : 8 }}
      >
        <span>{displayTitle}</span>
        <Arrows18
          direction={isOpen ? ArrowDirectionEnum.UP : ArrowDirectionEnum.DOWN}
        />
      </Container>
      {isOpen && (
        <Content
          style={{ maxHeight: scrollHeight ? scrollHeight : "fit-content" }}
        >
          {optionList.map((item, index) => {
            return (
              <span
                key={index}
                onClick={() => handleSelect(item.value)}
                className={item.value === selectedValue ? "selected" : ""}
              >
                {item.title}
              </span>
            );
          })}
        </Content>
      )}
    </div>
  );
}

const Container = styled.button`
  display: flex;
  padding: 7px 10px 7px 12px;
  width: fit-content;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.white};
  align-items: center;

  span {
    margin-right: 2px;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.3px;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 48px;
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  height: fit-content;
  width: fit-content;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  background: ${({ theme }) => theme.colors.white};
  z-index: 10;
  overflow-y: scroll;

  span {
    margin-bottom: 8px;
    padding: 4px 8px;
    min-width: 116px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.32px;
    border-radius: 8px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: var(--BlueGrayA100, #f4f6f8);
    }
  }

  .selected {
    background: ${({ theme }) => theme.colors.blue100};
    color: ${({ theme }) => theme.colors.blue1000};
    &:hover {
      background: ${({ theme }) => theme.colors.blue100}; /* hover 효과 무시 */
    }
  }
`;
