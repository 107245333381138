import MinusIcon from "@assets/icon/MinusIcon";
import PlusIcon from "@assets/icon/PlusIcon";
import NewsTable from "@components/page/admin/manage/news/NewsTable";
import { Button, ButtonWrapper, Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useDeleteNews, useGetNews } from "@hooks/management";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

export default function NewsList() {
  const navigate = useNavigate();
  const { data } = useGetNews();
  const { mutate } = useDeleteNews();

  const [selectedRow, setSelectedRow] = useState();

  const 뉴스_삭제 = () => {
    mutate({ id: selectedRow }, { onSuccess: () => toast("뉴스 삭제 성공") });
  };

  return (
    <Container>
      <Title>뉴스 업로드 관리</Title>
      <ButtonWrapper>
        <Button
          onClick={() => {
            navigate("/manage/news/add");
          }}
        >
          <PlusIcon />
          <p>기사 추가</p>
        </Button>
        <Button onClick={뉴스_삭제}>
          <MinusIcon />
          <p>기사 삭제</p>
        </Button>
      </ButtonWrapper>

      <NewsTable data={data?.news || []} setSelectedRow={setSelectedRow} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;
