import NavContainer from "@components/nav/NavContainer";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export default function StyledLayout() {
  return (
    <Container>
      {/* GNB */}
      <NavContainer />
      {/* 그 외 컨텐츠 */}
      <OutletWrapper>
        <div style={{ maxWidth: 1440, minWidth: 1440 }}>
          <Outlet />
        </div>
      </OutletWrapper>
    </Container>
  );
}

// viewport 화면 전체
const Container = styled.div``;

const OutletWrapper = styled.main`
  margin: 0 auto;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 110px 0 0px;
  min-width: 1560px;
  max-width: 1560px;
  height: 100%;
`;
