import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useGetImjangApplicantDetail } from "@hooks/imjang";
import TopRow from "./components/TopRow";
import Memo from "./components/Memo";
import ImjangProgress from "./components/ImjangProgress";
import Content from "./components/Content";
import CustomerInfo from "./components/CustomerInfo";
import StatusBox from "./components/StatusBox";
import Schedule from "./components/Schedule";
import { useImjang } from "@features/useImjang";
import { useGetAuthorities } from "@api/user";

export default function ApplicantDetail() {
  const { setGlobalEditPermission } = useImjang();

  const { data: authorities } = useGetAuthorities();
  const { id } = useParams();
  const { data } = useGetImjangApplicantDetail({ id });

  useEffect(() => {
    const bodyElement = document.body;
    bodyElement.style.backgroundColor = "#F4F6F8";

    return () => {
      bodyElement.style.backgroundColor = "#fff";
    };
  }, []);

  const statusCheck = useMemo(() => {
    if (!data) return null;
    if (data.state === "COMPLETED") {
      if (data.reward.length)
        return {
          state: "completed",
          reason: `혜택 지급 완료 : ${data.reward}`,
        };
      else
        return {
          state: "completed",
          reason: "혜택 지급하지 않음",
        };
    }
    if (data.isCancelled)
      return {
        state: "cancelled",
        reason: data.cancelReason,
      };
  }, [data]);

  useEffect(() => {
    if (!data) return;
    if (data?.isMyClient) {
      setGlobalEditPermission(true);
    } else {
      if (authorities?.includes("A04")) {
        setGlobalEditPermission(true);
      } else {
        setGlobalEditPermission(false);
      }
    }
  }, [data, authorities, setGlobalEditPermission]);

  return (
    <Container>
      <ContentRow>
        <h2 className="userName">
          {data?.contactInfo.name || ""}님 신청 상세정보
        </h2>
        <StatusBox status={statusCheck} id={id} />
        <Column>
          <CustomerInfo data={data ? [data] : []} />
          <ImjangProgress />
          {data?.isScheduled && (
            <Schedule list={data?.scheduleList || []} id={id} />
          )}
          <Content danjiInfo={data?.danjiInfo || []} reason={data?.reason} />
          {statusCheck?.state === "cancelled" && <BlurBox />}
        </Column>
      </ContentRow>

      <Fixed>
        <TopRow id={id} />
        <Memo id={id} />
      </Fixed>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  max-height: calc(100vh - 110px);
  overflow: scroll;
`;

const ContentRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 48px;
  padding-bottom: 40px;
  overflow: scroll;

  .userName {
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px; /* 131.25% */
    letter-spacing: -0.64px;
  }
`;

const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const BlurBox = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.4;
  border-radius: 16px;
  z-index: 100;
`;

const Fixed = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  padding-bottom: 40px;
  max-height: 1200px;
  /* overflow: hidden; */
`;
