import ImjangApplyReason from "@components/page/admin/imjang/imjangExpert/ImjangApplyReason";
import ImjangHouseTable from "@components/page/admin/imjang/imjangExpert/ImjangHouseTable";
import MaemulImjangTable from "@components/page/admin/imjang/imjangExpert/MaemulImjangTable";
import styled from "styled-components";

export default function Content({ danjiInfo, reason }) {
  return (
    <Container>
      <ImjangHouseTable data={danjiInfo} />
      {danjiInfo.every((item) => item.maemulInfo !== null) ? (
        <MaemulImjangTable
          data={danjiInfo.filter((item) => item.maemulInfo) || []}
        />
      ) : null}
      <ImjangApplyReason data={reason} />
    </Container>
  );
}

const Container = styled.div`
  margin-top: 24px;
  padding: 32px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
`;
