import styled from "styled-components";
import * as XLSX from "xlsx-js-style";
import ExcelIcon from "./ExcelIcon";
import { Minus16, Plus16 } from "@components/core/icon/plus";
import {
  useDeleteImjangDanji,
  useGetImjangApplicantDetail,
} from "@hooks/imjang";
import { useParams } from "react-router-dom";
import { useGetMaemulList } from "@hooks/management";
import moment from "moment";
import { useImjang } from "@features/useImjang";

export const tradeTypeObj = {
  sell: "매매",
  rent: "전세",
  none: "상관없음",
};

export default function ActionButtons({
  setIsVisible단지추가,
  getSelectedRow,
  handleDeselectAll,
}) {
  const { id } = useParams();
  const { data } = useGetImjangApplicantDetail({ id });
  const { globalImjangStatus } = useImjang();

  const { mutate } = useDeleteImjangDanji();
  const { mutateAsync: getMaemulMutate } = useGetMaemulList();
  const selectedDanjiList = getSelectedRow();

  const 단지_삭제 = () => {
    if (selectedDanjiList.some((el) => !el.danjiInfo.어드민추가여부)) {
      alert("유저가 추가한 단지는 삭제할 수 없습니다. 다시 선택해주세요.");
      return;
    }

    const idArr = selectedDanjiList
      .map((el) => {
        if (el.danjiInfo.어드민추가여부) return el.danjiInfo.id;
      })
      .filter(Boolean);

    mutate(
      { applyId: id, danjiIdList: idArr },
      {
        onSuccess: () => {
          alert("단지 삭제 완료");
          handleDeselectAll();
        },
      }
    );
  };

  const body = {
    danjiCode: 1003,
    scaleCodes: [4001],
    transactionType: "매매" as "매매" | "월세" | "전세" | "유효하지 않은 유형",
    sortType: "최신순" as "최신순" | "가격내림차순",
    offset: 0,
    limit: 1000,
    tag: {
      gm: false, // 급매
      sag: false, // 세안고
      ryd: false, // 로얄동
      nh: false, // 남향
      sl: false, // 수리
      hj: false, // 확장
      jm: false, // 전망
    },
  };

  function getMaemulTag(tags) {
    const tag = {
      gm: "급매",
      nh: "남향",
      ryd: "로얄동",
      sl: "수리",
      sag: "세안고",
      jm: "전망",
      hj: "확장",
    };

    const activeTags = Object.keys(tags)
      .filter((key) => tags[key])
      .map((el) => tag[el])
      .join(", ");

    return activeTags;
  }

  const 데이터_정리 = (el) => {
    return {
      단지명: el.danjiName,
      타입: el.pyeongType,
      거래구분: el.businessDiv,
      "매물 등록": el.updateDate,
      공급면적: el.supplyArea,
      전용면적: el.privateArea,
      평형: el.pyeong,
      "매매 금액": el.sellPrice?.toLocaleString() || "-",
      보증금: el.deposit?.toLocaleString() || "-",
      월세: el.monthlyRent?.toLocaleString() || "-",
      동: el.dong,
      층: el.floor,
      방향: el.direction,
      특이점: getMaemulTag(el.maemulTag) || "-",
      "상세 설명": el.detail || "-",
      "중개사 이름": el.agencyInfo
        ? `${el.agencyInfo.representativeName} (${el.agencyInfo.agencyName})`
        : "-",
      중개사무소: el.agencyInfo ? el.agencyInfo.phoneNumber : "-",
      "중개사 휴대폰": el.agencyInfo ? el.agencyInfo.cellPhone : "-",
    };
  };

  const 엑셀_파일_다운로드 = async () => {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let mapArr = [];

    selectedDanjiList.forEach((el) => {
      let insertIdx = mapArr.length; // 현재 mapArr의 길이만큼 idx 조정
      if (el.tradeType === "rent") {
        mapArr.splice(insertIdx, 0, { ...el, tradeType: "전세" });
        mapArr.splice(insertIdx + 1, 0, { ...el, tradeType: "월세" });
      } else if (el.tradeType === "sell") {
        mapArr.splice(insertIdx, 0, { ...el, tradeType: "매매" });
      } else if (el.tradeType === "none") {
        mapArr.splice(insertIdx, 0, { ...el, tradeType: "매매" });
        mapArr.splice(insertIdx + 1, 0, { ...el, tradeType: "전세" });
        mapArr.splice(insertIdx + 2, 0, { ...el, tradeType: "월세" });
      }
    });

    if (!mapArr.length) {
      alert("선택된 단지가 없습니다.");
      return;
    }

    try {
      const results = await Promise.all(
        mapArr.map(async (el, idx) => {
          // mutateAsync를 사용하여 비동기 요청 수행
          const res = await getMaemulMutate({
            ...body,
            danjiCode: el.danjiCode,
            scaleCodes: [el.scaleCode],
            transactionType: el.tradeType,
          });

          // 응답 데이터를 정리하고 시트 추가
          const cleanedData = res.maemulList.sellingInfoList.map(데이터_정리);
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(cleanedData);
          ws["!cols"] = [
            { wpx: 100 }, // 단지명
            { wpx: 50 }, // 타입
            { wpx: 50 }, // 거래구분
            { wpx: 100 }, // 매물 등록일
            { wpx: 50 }, // 공급면적
            { wpx: 50 }, // 전용면적
            { wpx: 50 }, // 평형
            { wpx: 100 }, // 매매 금액
            { wpx: 100 }, // 보증금
            { wpx: 100 }, // 월세
            { wpx: 50 }, // 동
            { wpx: 50 }, // 층
            { wpx: 50 }, // 방향
            { wpx: 100 }, // 특이점
            { wpx: 250 }, // 상세설명
            { wpx: 200 }, // 중개사무소 이름
            { wpx: 100 }, // 중개사무소 전화번호
            { wpx: 100 }, // 중개사무소 휴대폰번호
          ];

          return { idx, ws, el };
        })
      );
      const sheetNames = new Map(); // 중복된 시트 이름을 관리할 Map 생성

      results
        .sort((a, b) => a.idx - b.idx)
        .forEach(({ idx, ws, el }) => {
          let sheetName = `${el.danjiInfo.대표단지명} ${el.pyeongInfo.평타입}평(${el.pyeongInfo.공급면적_제곱미터}m²) ${el.tradeType}`;

          if (sheetNames.has(sheetName)) {
            let count = sheetNames.get(sheetName);
            count += 1;
            sheetName = `${sheetName} (${count})`; // 중복 시 (n) 번호 추가
            sheetNames.set(sheetName, count);
          } else {
            sheetNames.set(sheetName, 1);
          }

          XLSX.utils.book_append_sheet(wb, ws, sheetName);
        });

      XLSX.writeFile(
        wb,
        `${data.contactInfo.name}님 매물 리스트_ ${moment().format(
          "YYYYMMDD"
        )}.xlsx`
      );
    } catch (err) {
      console.error("파일 생성 실패", err);
      alert("파일 생성 실패, 개발팀에게 문의해주세요.");
    }
  };

  return (
    <Row>
      {globalImjangStatus.editPermission && (
        <>
          <AddButton onClick={() => setIsVisible단지추가(true)}>
            <Plus16 />
            <p> 단지 추가하기</p>
          </AddButton>
          <AddButton onClick={단지_삭제}>
            <Minus16 />
            <p> 단지 삭제하기</p>
          </AddButton>
        </>
      )}
      <DownloadButton onClick={엑셀_파일_다운로드}>
        <ExcelIcon />

        <p>선택단지 매물 다운로드</p>
      </DownloadButton>
    </Row>
  );
}

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  gap: 12px;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  border-radius: 10px;
  cursor: pointer;

  p {
    margin-left: 6px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }
`;

const DownloadButton = styled(AddButton)`
  p {
    color: var(--White, #fff);
  }

  background-color: #1f7043;
  border: none;
`;
