// import {CoreInputProps} from '../commonInput.interfaces';

export enum TextInputEnum {
  DEFAULT = "DEFAULT",
  NAME = "NAME",
  NICKNAME = "NICKNAME",
  PHONE_NUM = "PHONE_NUM",
  EMAIL = "EMAIL",
}

// export interface Props extends CoreInputProps {
//   maxLength?: number;
//   textType: TextInputEnum;
// }
