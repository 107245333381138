import { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Tooltip from "./Tooltip";
import { useGetImjangApplicantDetail, useGetImjangStatus } from "@hooks/imjang";
import ProgressModal from "./ProgressModal";
import useClickOutside from "@hooks/useClickOutside";
import { useImjang } from "@features/useImjang";

export default function ImjangProgress() {
  const { id } = useParams();
  const { data } = useGetImjangStatus({ filter: "detail" });
  const { data: detailData } = useGetImjangApplicantDetail({ id });

  return (
    <Container>
      <TitleRow />
      <Bar state={detailData?.state} data={data} />
    </Container>
  );
}

const TitleRow = () => {
  const tooltipRef = useRef(null);
  const { globalImjangStatus } = useImjang();
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);

  // 다른 곳 클릭시 툴팁 닫기
  useClickOutside([tooltipRef], () => setIsGuideOpen(false));

  const handleGuideClick = () => {
    setIsGuideOpen(!isGuideOpen);
  };

  const handleStatusClick = () => {
    setIsStatusOpen(!isStatusOpen);
  };

  return (
    <Row>
      <div className="header">
        <h2>임장 진행 상태</h2>
        <button className="guide" onClick={handleGuideClick}>
          <GuideIcon />
          <span>상태 가이드</span>
        </button>
        {isGuideOpen && <Tooltip ref={tooltipRef} />}
      </div>
      {globalImjangStatus.editPermission && (
        <button className="change" onClick={handleStatusClick}>
          상태 변경
        </button>
      )}

      <ProgressModal isVisible={isStatusOpen} setIsVisible={setIsStatusOpen} />
    </Row>
  );
};

const Bar = ({ state, data }) => {
  const currentStep = useMemo(
    () => data?.findIndex((item) => item.value === state),
    [data, state]
  );

  return (
    <BarContainer>
      <div className="backBar" />
      <div
        className="frontBar"
        style={{ width: currentStep > 0 ? 102 * currentStep : 0 }}
      />
      {data?.map((item, index) => {
        const step =
          currentStep === index ? "now" : currentStep > index ? "prev" : "";
        return (
          <div className="step" key={index}>
            <Dot step={step} />
            <span className={step === "now" ? "bold" : ""}>{item.name}</span>
          </div>
        );
      })}
    </BarContainer>
  );
};

const Container = styled.div`
  margin-top: 24px;
  padding: 32px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  .header {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-right: 12px;
    height: 38px;

    h2 {
      color: ${({ theme }) => theme.colors.blueGray1000};
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: -0.36px;
    }

    .guide {
      display: flex;
      align-items: center;
      margin-left: 12px;
      padding: 4px 8px;
      border-radius: 8px;
      border: 1px solid var(--BlueGray100, #e5e7e9);

      span {
        margin-left: 4px;
        color: ${({ theme }) => theme.colors.blueGray600};
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.24px;
      }
    }
  }

  .change {
    display: flex;
    padding: 8px 12px;
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.blue1000};
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }
`;

const BarContainer = styled.div`
  position: relative;
  display: flex;
  height: 64px;

  .backBar {
    position: absolute;
    top: 14px;
    left: 49px;
    width: calc(100% - 98px);
    height: 4px;
    background: ${({ theme }) => theme.colors.blueGray100};
  }

  .frontBar {
    position: absolute;
    top: 14px;
    left: 49px;
    height: 4px;
    background: ${({ theme }) => theme.colors.blue1000};
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    z-index: 2;

    span {
      margin-top: 8px;
      color: ${({ theme }) => theme.colors.blueGray700};
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.28px;
    }

    .bold {
      color: ${({ theme }) => theme.colors.blueA700};
      font-weight: 700;
    }
  }
`;

const GuideIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_21936_17816)">
        <path
          d="M7.00033 1.16732C10.222 1.16732 12.8337 3.77899 12.8337 7.00065C12.8337 10.2223 10.222 12.834 7.00033 12.834C3.77866 12.834 1.16699 10.2223 1.16699 7.00065C1.16699 3.77899 3.77866 1.16732 7.00033 1.16732Z"
          stroke="#808690"
          strokeWidth="1.2"
          strokeMiterlimit="10"
        />
        <path
          d="M7.58366 9.56901C7.58366 9.89118 7.32249 10.1523 7.00033 10.1523C6.67816 10.1523 6.41699 9.89118 6.41699 9.56901V6.06901C6.41699 5.74684 6.67816 5.48568 7.00033 5.48568C7.32249 5.48568 7.58366 5.74684 7.58366 6.06901V9.56901Z"
          fill="#808690"
        />
        <path
          d="M7.58366 4.31901C7.58366 4.64118 7.32249 4.90234 7.00033 4.90234C6.67816 4.90234 6.41699 4.64118 6.41699 4.31901C6.41699 3.99684 6.67816 3.73568 7.00033 3.73568C7.32249 3.73568 7.58366 3.99684 7.58366 4.31901Z"
          fill="#808690"
        />
      </g>
    </svg>
  );
};

interface DotProps {
  step?: "now" | "prev" | "";
}

const Dot = ({ step }: DotProps) => {
  const commonProps = {
    width: "33",
    height: "32",
    viewBox: "0 0 33 32",
    fill: "none",
  };

  return (
    <svg {...commonProps}>
      {step === "now" && (
        <>
          <circle
            cx="16.4443"
            cy="16"
            r="12.7"
            fill="white"
            stroke="#1C84FF"
            strokeWidth="1.4"
          />
          <circle cx="16.4443" cy="16" r="6" fill="#1C84FF" />
        </>
      )}
      {step === "prev" && <circle cx="16.666" cy="16" r="6" fill="#1C84FF" />}
      {!step && <circle cx="16.333" cy="16" r="6" fill="#E5E7E9" />}
    </svg>
  );
};
