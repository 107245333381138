import { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import {
  borderColorObj,
  InputStatusEnum,
  InputTypeEnum,
} from "../BTypeInput.interfaces";

interface Props {
  inputType: InputTypeEnum;
  // label: string;
  value: any;
  placeholder: string;
  onChangeValue: (v: any) => void;
  handleFocus?: () => void;
  handleBlur?: () => void;
  disabled?: boolean;
  optionList: { label: string; value: any }[];
}

export default function BTypeBtn48({
  value = null,
  placeholder,
  onChangeValue,
  handleFocus = () => {},
  handleBlur = () => {},
  disabled = false,
  optionList = [],
}: Props) {
  const selectRef = useRef(null);
  const [status, setStatus] = useState<InputStatusEnum>(
    InputStatusEnum.Default
  );

  const onFocus = () => {
    setStatus(value ? InputStatusEnum.Active : InputStatusEnum.Focus);
    handleFocus && handleFocus();
  };

  const onBlur = (e) => {
    e.preventDefault();
    setStatus(value ? InputStatusEnum.Completion : InputStatusEnum.Default);
    handleBlur && handleBlur();
  };

  const 클릭 = () => {
    selectRef.current.focus();
  };

  return (
    <InputContainer
      className={[
        status === InputStatusEnum.Disabled ? "disabledBG" : "defaultBG",
      ].join(" ")}
      style={{
        borderColor: borderColorObj[status],
      }}
      onClick={클릭}
      $disabled={false}
      value={""}
    >
      {/* {value ? (
        <InputValue>{value}</InputValue>
      ) : (
        <PlaceHolder>{placeholder}</PlaceHolder>
      )} */}
      {/* <Arrow>
        <Arrows22
          direction={ArrowDirectionEnum.DOWN}
          color={ArrowColorEnum.B20}
        />
      </Arrow> */}
      <select
        ref={selectRef}
        onChange={onChangeValue}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        value={value}
      >
        {optionList.map((el, idx) => (
          <option value={el.value} key={idx}>
            {el.label}
          </option>
        ))}
      </select>
    </InputContainer>
  );
}

const InputContainer = styled.div<{
  value: string | number;
  // $isFocus: boolean;
  $disabled: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  height: 48px;
  border-radius: 10px;

  &.disabledBG {
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  &.defaultBG {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  } */
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.blueGray100 : theme.colors.blueGrayA100};

  select {
    width: 100%;
    border: none;
    outline: none;
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.blueGray100 : theme.colors.blueGrayA100};
    color: ${({ theme, value }) =>
      value ? theme.colors.blueGray800 : theme.colors.blueGray500};

    color: var(--BlueGray800, #333e4d);

    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;

const PlaceHolder = styled.p`
  color: ${({ theme }) => theme.colors.blueGray500};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
`;

const InputValue = styled(PlaceHolder)`
  color: ${({ theme }) => theme.colors.blueGray800};
`;

const Arrow = styled.div`
  margin-left: auto;
`;
