import alertSlice from "@features/alertSlice";
import consultantSlice from "@features/consultantSlice";
import imjangSlice from "@features/imjangSlice";
import routerSlice from "@features/routerSlice";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    alert: alertSlice,
    router: routerSlice,
    consultantSlice,
    imjangSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
