import { useCheckSignUp } from "@api/login";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { Close32 } from "@components/core/icon/close";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { TextInputEnum } from "@components/input/BTypeText48/BTypeText.interfaces";
import BTypeText48 from "@components/input/BTypeText48/BTypeText48";
import { useState } from "react";
import styled from "styled-components";
import ConnectAccount from "./ConnectAccount";
import { useAlert } from "@features/useAlert";

export default function AlreadySignUp() {
  const [isVisible가입정보입력, setIsVisible가입정보입력] = useState(false);
  const [isVisible계정통합, setIsVisible계정통합] = useState(false);
  const { mutate, data } = useCheckSignUp();
  const { showGlobalAlert } = useAlert();

  // 이메일 정보는 변경할 수 없으므로 바깥 userInfo랑 같이 사용 X
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    phone_number: "",
  });

  const 가입정보조회 = (e) => {
    e.preventDefault();
    mutate(
      {
        name: userInfo.name,
        email: userInfo.email,
        phone_number: userInfo.phone_number,
      },
      {
        onSuccess: (res) => setIsVisible계정통합(true),
        onError: (res) => {
          if (res.response.data?.["message"].includes("세션")) {
            showGlobalAlert({
              desc: "세션이 만료되었습니다. \n다시 시도해 주세요.",
              blue: false,
              path: "/login",
            });
          } else {
            showGlobalAlert({
              desc: "가입한 정보를 찾을 수 없어요. \n가입한 정보가 맞는지 확인해주세요.",
              blue: false,
            });
          }
        },
      }
    );
  };

  return (
    <Container>
      <IsExist onClick={() => setIsVisible가입정보입력(true)}>
        <p>이미 회원이 있나요?</p>
      </IsExist>
      <PopUpContainer
        isVisible={isVisible가입정보입력}
        setIsVisible={setIsVisible가입정보입력}
      >
        <UserInfoForm>
          <h1 className="title">가입했던 정보를 입력해주세요</h1>
          <span
            className="icon"
            onClick={() => setIsVisible가입정보입력(false)}
          >
            <Close32 />
          </span>
          <form onSubmit={가입정보조회}>
            <TRow>
              <div className="head">
                <p>이름</p>
                <span className="required">*</span>
              </div>
              <div className="body">
                <BTypeText48
                  inputType={InputTypeEnum.OnlyText}
                  value={userInfo.name}
                  placeholder="이름을 입력해주세요"
                  onChangeValue={(e) =>
                    setUserInfo((prev) => ({ ...prev, name: e.target.value }))
                  }
                  textType={TextInputEnum.NAME}
                />
              </div>
            </TRow>
            <TRow>
              <div className="head">
                <p>휴대폰 번호</p>
                <span className="required">*</span>
              </div>
              <div className="body">
                <BTypeText48
                  inputType={InputTypeEnum.OnlyText}
                  value={userInfo.phone_number}
                  placeholder="예) 01012345678"
                  onChangeValue={(e) =>
                    setUserInfo((prev) => ({
                      ...prev,
                      phone_number: e.target.value,
                    }))
                  }
                  textType={TextInputEnum.PHONE_NUM}
                />
              </div>
            </TRow>
            <TRow>
              <div className="head">
                <p>이메일 주소</p>
                <span className="required">*</span>
              </div>
              <div className="body">
                <BTypeText48
                  inputType={InputTypeEnum.OnlyText}
                  value={userInfo.email}
                  placeholder="이메일을 입력해주세요"
                  onChangeValue={(e) =>
                    setUserInfo((prev) => ({ ...prev, email: e.target.value }))
                  }
                  textType={TextInputEnum.EMAIL}
                />
              </div>
            </TRow>
            <button onClick={가입정보조회}>
              <p>확인</p>
            </button>
          </form>
        </UserInfoForm>
      </PopUpContainer>
      <PopUpContainer
        isVisible={isVisible계정통합}
        setIsVisible={setIsVisible계정통합}
      >
        <ConnectAccount
          setIsVisible={setIsVisible계정통합}
          userInfo={userInfo}
          data={data}
        />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div``;

const IsExist = styled.button`
  margin-top: 10px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 18px;
  width: 502px;
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 600;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;

const UserInfoForm = styled.div`
  padding: 24px 20px 20px;
  width: 502px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 24px;

  .title {
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.4px;
    text-align: center;
  }

  .icon {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  form {
    margin-top: 24px;
  }

  button {
    margin-top: 24px;
    height: 48px;
    width: 100%;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.primaryBlue100};

    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;
    cursor: pointer;
  }
`;

const TRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .head {
    display: flex;
    position: relative;
    top: 13px;
    width: 140px;

    p {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }

    .required {
      margin-left: 2px;
      color: ${({ theme }) => theme.colors.red1000};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  .body {
    width: 312px;
  }
`;
