export function MouseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="30"
      viewBox="0 0 20 30"
      fill="none"
    >
      <rect
        x="1"
        y="1"
        width="18"
        height="28"
        rx="9"
        stroke="#1A2637"
        stroke-width="2"
      />
      <path
        d="M10 6V12"
        stroke="#1A2637"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}

export function RightArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <rect width="18" height="18" fill="white" />
      <path
        d="M5 1L13 9L5 17"
        stroke="#1A2637"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
