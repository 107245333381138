import styled from "styled-components";

import { useEffect, useState } from "react";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { CheckBoxEnum, SquareCheckBox20 } from "@components/core/icon/checkBox";

export default function CoinPush({ coinInfo, setCoinInfo }) {
  const [sendPush, setSendPush] = useState(true);

  useEffect(() => {
    if (!sendPush) {
      setCoinInfo((prev) => ({ ...prev, push_title: "", push_body: "" }));
    }
  }, [sendPush]);

  return (
    <Container>
      <Row style={{ marginBottom: 10 }}>
        <span onClick={() => setSendPush((prev) => !prev)}>
          <SquareCheckBox20
            status={sendPush ? CheckBoxEnum.Activate : CheckBoxEnum.Default}
          />
        </span>
        <p>코인 충전 후 푸시 전송 함</p>
      </Row>

      <Input
        placeholder="타이틀"
        style={{
          width: "100%",
          backgroundColor: sendPush ? "#f4f6f8" : "#e5e7e6",
        }}
        value={coinInfo.push_title}
        onChange={(e) =>
          setCoinInfo((prev) => ({ ...prev, push_title: e.target.value }))
        }
      />
      <BTypeTextArea
        placeholder="설명 (선택사항)"
        textareaStyle={{
          width: "100%",
          height: 84,
          backgroundColor: sendPush ? "#f4f6f8" : "#e5e7e6",
        }}
        value={coinInfo.push_body}
        onChangeValue={(v) =>
          setCoinInfo((prev) => ({ ...prev, push_body: v }))
        }
      />
    </Container>
  );
}

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0 0 2px 8px;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }
`;

const Input = styled.input`
  padding: 10px 20px;
  flex: 1;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.blueGray100};
    cursor: not-allowed;
  }
`;
