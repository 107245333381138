import styled from "styled-components";
import { useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";

import CustomerCard from "@pages/admin/imjang/application/components/CustomerCard";
import Progress from "@pages/admin/imjang/application/components/Progress";
import { useGetImjangApplyList, useGetImjangStatus } from "@hooks/imjang";
import { useImjang } from "@features/useImjang";

export default function KanbanBoard() {
  const { data } = useGetImjangStatus({ filter: "main" });

  if (!data) return null;

  return (
    <Container>
      {data.map((obj, index) => (
        <Column key={index} data={obj} />
      ))}
    </Container>
  );
}

const Column = ({ data }) => {
  const queryClient = useQueryClient();
  const { globalImjangStatus } = useImjang();
  const { data: dataList, isLoading } = useGetImjangApplyList({
    filter: {
      statusList: [data.value],
      madminId: globalImjangStatus.searchFilter.madminId || "",
      applyUserName: globalImjangStatus.searchFilter.applyUserName || "",
    },
  });

  const refetch = () => {
    queryClient.invalidateQueries({
      queryKey: [
        "imjangApplicant",
        [data.value],
        globalImjangStatus.searchFilter.madminId || "",
        "",
      ],
    });
  };

  const dataArr = useMemo(() => {
    if (!dataList) return [];
    return dataList.applyList.sort(
      (a, b) => (b.isScrapped ? 1 : -1) - (a.isScrapped ? 1 : -1)
    );
  }, [dataList]);

  return (
    <KanbanContainer>
      <Progress title={data.name} count={dataList?.applyList.length} />
      <CardContainer>
        {isLoading && <Loading />}
        {dataArr.map((data, index) => (
          <CustomerCard
            key={`${index}_${data.applyId}`}
            value={data}
            refetch={refetch}
          />
        ))}
        {dataArr.length === 0 && !isLoading && <div style={{ height: 160 }} />}
      </CardContainer>
    </KanbanContainer>
  );
};

const Container = styled.div`
  display: flex;
  margin-top: 32px;
  padding: 0 4px;
  width: 100%;
  overflow-x: auto;
  gap: 16px;
`;

const KanbanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  & > * {
    margin-bottom: 8px;
  }
`;

const Loading = styled.div`
  background-color: #ebebeb;
  width: 280px;
  height: 160px;
  border-radius: 16px;
  display: inline-flex;
  line-height: 1;
  position: relative;
  user-select: none;
  overflow: hidden;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      90deg,
      #ebebeb 0%,
      #f5f5f5 50%,
      #ebebeb 100%
    );
    transform: translateX(-100%);
    animation: react-loading-skeleton 1.5s ease-in-out infinite;
  }

  @keyframes react-loading-skeleton {
    100% {
      transform: translateX(100%);
    }
  }

  @media (prefers-reduced-motion) {
    &::after {
      display: none;
    }
  }
`;
