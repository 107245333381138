import { ActionEnum } from "@components/core/icon/action/Action.interfaces";
import Action24 from "@components/core/icon/action/Action24";
import { useCreatePushGroup, useGetPushGroup } from "@hooks/management";
import { useRef, useState } from "react";
import Papa from "papaparse";
import styled from "styled-components";
import { FadeLoader } from "react-spinners";

export default function FileUploadPopup({ setIsVisible }) {
  const { mutate, isLoading } = useCreatePushGroup();
  const { data } = useGetPushGroup();

  const inputRef = useRef(null);
  const [file, setFile] = useState<{ file: File; name: string }>({
    file: null,
    name: null,
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleClick = () => {
    inputRef.current.click();
  };

  const 파일_업로드 = (e) => {
    setErrorMessage("");
    const fileName = e.target.files[0].name.replace(/\.csv$/, "");
    setFile({
      file: e.target.files[0],
      name: fileName,
    });
  };

  const 이름_체크 = () => {
    if (data.targets.some((el) => el.target_name === file.name)) {
      if (
        window.confirm(
          "동일한 이름의 그룹이 있습니다. 새로 추가한 항목으로 대치하겠습니까?"
        )
      ) {
        그룹_등록();
      }
    } else {
      그룹_등록();
    }
  };

  const csv_변환 = async () => {
    const userIdArr = [];

    const parseCSV = () =>
      new Promise((resolve, reject) =>
        Papa.parse(file.file, {
          header: true,
          complete: (results) => {
            resolve(results.data);
          },
          error: (error) => {
            reject(error);
          },
        })
      );

    try {
      const results = await parseCSV();
      const cleanedData = (results as { ["gp:User_no.id"]: string }[]).map(
        (row) =>
          Object.fromEntries(
            Object.entries(row).map(([key, value]) => [
              key.trim(),
              value.trim(),
            ])
          )
      );

      cleanedData.forEach((el) => {
        if (el["gp:User_no.id"]) {
          userIdArr.push(el["gp:User_no.id"]);
        }
      });

      return userIdArr;
    } catch (error) {
      console.error("에러 발생:", error);
      throw error;
    }
  };

  const 그룹_등록 = () => {
    if (isLoading) return;
    csv_변환()
      .then((result) =>
        mutate(
          {
            target_name: file.name,
            // target_users: ["666aa9a3a67a55fb4b64948b"],
            target_users: result,
          },
          {
            onSuccess: () => {
              alert("새로운 그룹이 생성되었습니다.");
              setIsVisible(false);
            },
            onError: (res) => {
              setErrorMessage(res.response.data["msg"]);
              switch (res.response?.status) {
                case 405:
                  alert(
                    "파일 이름에 사용 불가한 문자가 포함되어있습니다. 파일명을 수정해주세요. (한글 불가)"
                  );
                  break;
                case 444:
                  alert(
                    "실제 유저가 0명인 그룹입니다. csv 파일을 확인해주세요."
                  );
                  break;
                case 400:
                  alert("파일명을 작성해주세요.");
                  break;
                default:
                  alert("그룹 등록 실패");
                  break;
              }
            },
          }
        )
      )
      .catch((error) => {
        console.log("그룹 등록 실패");
      });
  };

  return (
    <Container>
      <Title>푸시 그룹 추가</Title>
      {!file.file ? (
        <Content>
          <button className="button" onClick={handleClick}>
            + 파일 등록
          </button>

          <p className="light" style={{ marginTop: 6 }}>
            * csv 형식의 파일만 첨부 가능합니다.
          </p>

          <input
            type="file"
            accept=".csv"
            ref={inputRef}
            onChange={(e) => 파일_업로드(e)}
            style={{ visibility: "hidden" }}
          />
        </Content>
      ) : (
        <Content style={{ alignItems: "flex-start" }}>
          <InputContainer>
            <input
              type="file"
              accept=".csv"
              className="fileInput"
              ref={inputRef}
              onChange={(e) => 파일_업로드(e)}
              style={{ visibility: "hidden" }}
            />
            <p className="label">등록 파일</p>
            <Row style={{ justifyContent: "space-between" }}>
              <input
                value={file.name}
                onChange={(e) =>
                  setFile((prev) => ({ ...prev, name: e.target.value }))
                }
              />
              <p className="change" onClick={handleClick}>
                파일 변경
              </p>
            </Row>
          </InputContainer>
          <ErrorText>{errorMessage}</ErrorText>
          <Caution>
            <Action24 status={ActionEnum.CAUTION} />
            <p>
              그룹 생성을 위해 일정 시간이 필요합니다. {"\n"}생성 후 팝업이
              닫히기 까지 잠시만 기다려주세요.
            </p>
          </Caution>
          <Btn onClick={이름_체크}>
            {isLoading ? (
              <FadeLoader
                cssOverride={{
                  width: 10,
                  height: 10,
                  transform: "scale(0.3)",
                  top: 0,
                  left: 0,
                }}
                color="white"
              />
            ) : (
              <p>그룹 생성</p>
            )}
          </Btn>
        </Content>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  width: 460px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray800};
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px; /* 108.333% */
  letter-spacing: -0.48px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;

  .button {
    width: 125px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.blue100};
    cursor: pointer;

    color: var(--Blue1000, #1c84ff);
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  .light {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }
`;

const Caution = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;

  p {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    white-space: pre;
  }
`;

const Btn = styled.button`
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  padding: 9px 0;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrayA200};
  margin-top: 24px;

  .fileInput {
    display: none;
  }

  .label {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  input {
    display: flex;
    flex-grow: 2;
    margin: 8px 0 4px;
    border: none;
    height: 32px;

    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 400;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;

    &:focus {
      outline: none;
    }
  }

  .change {
    color: ${({ theme }) => theme.colors.blueGray500};
    text-align: right;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    cursor: pointer;
  }
`;

const ErrorText = styled.p`
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.red1000};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
`;
