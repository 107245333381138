import { useGetAuthorities, useMutateAuthorities } from "@api/user";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function PermissionLayout({
  pageValue,
  style,
}: {
  pageValue: string;
  style?: React.CSSProperties;
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { mutate } = useMutateAuthorities();

  useEffect(() => {
    setIsLoading(true);

    mutate(undefined, {
      onSuccess: (res) => {
        if (res.includes(pageValue)) {
          setIsAuthenticated(true);
        } else {
          navigate("/403");
        }
        setIsLoading(false);
      },
      onError: () => {
        navigate("/login");
      },
    });
  }, [pageValue]);

  if (isLoading) {
    return <AbsoluteFillLoading />;
  }

  if (isAuthenticated) {
    return (
      <Container style={{ ...style }}>
        <Outlet />
      </Container>
    );
  }
}

const Container = styled.div`
  /* padding: 48px 242px 0;
  max-width: 1440px;
  min-width: 1440px; */
`;
