import {
  createColumnHelper,
  flexRender,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import moment from "moment";
import styled from "styled-components";

import { getCoreRowModel } from "@tanstack/react-table";

import "@components/table.css";
import ChangePerson from "./ChangePerson";

export default function CustomerInfo({ data }) {
  const columnHelper = createColumnHelper();

  const managerList = useMemo(() => {
    if (!data) return [];
    if (data.length === 0) return [];
    return data[0].madminList;
  }, [data]);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("applyDate", {
        cell: ({ getValue }) => {
          const StringDate = new Date(String(getValue()));
          StringDate.setHours(StringDate.getHours() + 9);

          return <p>{moment(StringDate).format("YYYY년 M월 DD일 HH:mm")}</p>;
        },
        header: "신청일",
        maxSize: 20,
      }),
      columnHelper.accessor("isWebUser", {
        cell: ({ getValue }) => {
          return getValue() ? "웹" : "앱";
        },
        header: "신청경로",
        maxSize: 15,
      }),
      columnHelper.accessor("name", {
        cell: ({ row }) => {
          const userName = row.original["contactInfo"]["name"];
          return <p>{userName}</p>;
        },
        header: "고객이름",
        maxSize: 16,
      }),
      columnHelper.accessor("number", {
        cell: ({ row }) => {
          const phone = row.original["contactInfo"]["phoneNumber"];
          const phoneStr = phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");

          return <p>{phoneStr}</p>;
        },
        header: "연락처",
        maxSize: 25,
      }),
      columnHelper.accessor("madminList", {
        cell: () => {
          return managerList?.map((el) => el.name).join(",");
        },
        header: "담당자",
        maxSize: 25,
      }),
    ];
  }, [managerList, columnHelper]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <TitleRow>
        <SubTitle>고객 정보</SubTitle>
        <ChangePerson managerList={managerList} />
      </TitleRow>
      <Table>
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getAllCells().map((cell) => (
                <td key={cell.id} className="contentsData">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.white};
`;

const Table = styled.table`
  width: 100%;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const SubTitle = styled.h2`
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;
