import {
  createColumnHelper,
  flexRender,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";

import "@components/table.css";
import { Link } from "react-router-dom";
import Pagination from "@components/core/paging/Pagination";
import { convertToKoreanTime } from "@hooks/calc/converToKoreanDate";

import { hyphenPhoneNum } from "@utils/phoneNumber";
import {
  ApplicantInfo,
  ConsultationInfo,
  ConsultationStatusEnum,
} from "@hooks/consultation/Consulting.interfaces";
import StatusChip from "@components/common/StatusChip";
import UnderLineCopyCell from "@components/common/UnderLineCopyCell";

export default function ApplicantListTable({
  data,
  setIsVisible,
  handleState,
}) {
  console.log({ data });

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("isWebUser", {
        header: "경로",
        maxSize: 5,
        cell: ({ getValue }) => (getValue() ? "Web" : "App"),
      }),
      columnHelper.accessor("state", {
        cell: ({ getValue, row }) => {
          const original = row.original as ApplicantInfo;
          return (
            <Center
              onClick={() => {
                setIsVisible(true);
                handleState({
                  applicantId: original.applicantId,
                  currentState: original.state,
                });
              }}
            >
              <StatusChip status={getValue()} />
            </Center>
          );
        },
        header: "진행상태",
        maxSize: 10,
      }),
      columnHelper.accessor("applyDate", {
        cell: ({ getValue }) => {
          return convertToKoreanTime(getValue());
        },
        header: "신청날짜",
        maxSize: 15,
      }),
      columnHelper.accessor("applicantName", { header: "신청자", maxSize: 6 }),
      columnHelper.accessor("phoneNumber", {
        cell: ({ getValue }) => (
          <UnderLineCopyCell content={hyphenPhoneNum(getValue())} />
        ),
        header: "신청자 연락처",
        maxSize: 11,
      }),
      columnHelper.accessor("email", {
        cell: ({ getValue }) => <UnderLineCopyCell content={getValue()} />,
        header: "신청자 이메일",
        maxSize: 18,
      }),
      columnHelper.accessor("influencer", {
        cell: ({ row }) => {
          const original = row.original as ApplicantInfo;
          return original.influencer.name;
        },
        header: "담당 컨설턴트",
        maxSize: 9,
      }),

      columnHelper.accessor("type", {
        header: "상담방식",
        maxSize: 6,
      }),
      columnHelper.accessor("price", {
        cell: ({ getValue }) => {
          return getValue().toLocaleString() + "원";
        },
        header: "상담금액",
        maxSize: 9,
      }),
      columnHelper.accessor("applicantId", {
        cell: ({ getValue, row }) => {
          return (
            <StyledLink to={`/consultation/application/${getValue()}`}>
              상세정보 보기
            </StyledLink>
          );
        },
        header: "상세정보",
        maxSize: 11,
      }),
    ];
  }, []);

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const state = (row.original as ConsultationInfo).state;
            const disabled =
              state === ConsultationStatusEnum.상담취소 ||
              state === ConsultationStatusEnum.상담완료;
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="contentsData"
                    style={{
                      padding: "12px 0",
                      color: disabled ? "#B2B7BC" : "#4D5664",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  text-decoration: underline;
  text-underline-position: under;

  &:visited {
    color: ${({ theme }) => theme.colors.blue700};
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
