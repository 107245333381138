import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import CircleCheckRow from "@components/core/list/CircleCheckRow";
import ModalContainer from "@components/core/modal/ModalContainer";
import GradientButton from "@components/button/GradientButton";
import {
  useGetImjangApplicantDetail,
  usePatchImjangCancel,
} from "@hooks/imjang";
import { useImjang } from "@features/useImjang";
import { useParams } from "react-router-dom";

interface TopRowProps {
  id: string;
}

export default function TopRow({ id }: TopRowProps) {
  const [isVisible, setIsVisible] = useState(false);
  const { globalImjangStatus } = useImjang();
  const { data } = useGetImjangApplicantDetail({ id });

  const handleDelete = () => {
    if (data?.state === "COMPLETED") {
      alert(
        `완료 상태에서는 임장 비서 끝내기가 불가능합니다.${"\n"}상태를 변경한 후 다시 시도해 주세요.`
      );
      return;
    }
    setIsVisible(true);
  };

  return (
    <>
      <ButtonBox>
        <Button color="#F9E000" style={{ marginRight: 8, paddingLeft: 16 }}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M11.9998 3.23242C6.54052 3.23242 2.11523 6.7301 2.11523 11.0471C2.11523 13.8561 3.98912 16.3179 6.80369 17.6953C6.59652 18.4662 6.05525 20.4912 5.947 20.9242C5.81262 21.4618 6.14297 21.4543 6.36135 21.3106C6.53119 21.1967 9.06953 19.4703 10.1651 18.7256C10.7605 18.8133 11.3727 18.86 11.9998 18.86C17.4591 18.86 21.8844 15.3623 21.8844 11.0471C21.8844 6.73197 17.4591 3.23242 11.9998 3.23242Z"
              fill="#371C1D"
            />
          </svg>
          <a
            style={{ color: "#1A2637", marginLeft: 6 }}
            href="https://center-pf.kakao.com/_wtlmK/chats"
            target="_blank"
            rel="noreferrer"
          >
            채널톡 이동
          </a>
        </Button>
        {!data?.isCancelled && globalImjangStatus.editPermission && (
          <Button color="#F04452" onClick={handleDelete}>
            <span>임장 비서 끝내기</span>
          </Button>
        )}
      </ButtonBox>
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} />
    </>
  );
}

const Modal = ({ isVisible, setIsVisible }) => {
  const { id } = useParams();
  const reasonArr = [
    { id: 1, text: "고객 단순변심" },
    { id: 2, text: "다른 중개사와 임장을 진행" },
    { id: 3, text: "계약 보류" },
    { id: 4, text: "동일 고객 재신청" },
    { id: 5, text: "직접입력" },
  ];
  const { mutate, isLoading } = usePatchImjangCancel({ id });
  const [selected, setSelected] = useState(1);
  const [text, setText] = useState("");
  const clickRef = useRef(false);

  const handleSelect = (id: number) => {
    setSelected(id);
  };

  const buttonData = {
    text: "임장 비서 끝내기",
    onClick: () => {
      const reason =
        selected === 5
          ? text
          : reasonArr.find((item) => item.id === selected)?.text;
      if (isLoading) return;
      if (clickRef.current) return;
      clickRef.current = true;

      mutate(
        {
          isCancelled: true,
          reason,
        },
        {
          onSuccess: () => {
            setTimeout(() => {
              clickRef.current = false;
            }, 300);
            setIsVisible(false);
          },
        }
      );
    },
  };

  const isDone = useMemo(() => {
    if (selected !== 5) return true;
    return selected === 5 && text.length > 0;
  }, [selected, text]);

  useEffect(() => {
    setText("");
  }, [selected]);

  useEffect(() => {
    if (!isVisible) {
      setSelected(1);
      setText("");
    }
  }, [isVisible]);

  return (
    <ModalContainer
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onCloseButton
      title="임장 비서 중단 사유"
    >
      <ModalBody>
        {reasonArr.map((item, index) => {
          const check = selected === item.id;
          return (
            <div
              key={`${index}_${item.id}`}
              onClick={() => handleSelect(item.id)}
            >
              <CircleCheckRow
                text={item.text}
                active={check}
                activeColor="#F04452"
              />
            </div>
          );
        })}
        <TextBox>
          {selected === 5 && (
            <TextArea
              placeholder="종료 사유 입력"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          )}
        </TextBox>
      </ModalBody>
      <div style={{ padding: "0 8px" }}>
        <GradientButton
          text={buttonData.text}
          onClick={buttonData.onClick}
          isDone={isDone}
          style={{ backgroundColor: "#F04452" }}
        />
      </div>
    </ModalContainer>
  );
};

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 14px;
`;

const Button = styled.button<{ color?: string }>`
  display: flex;
  align-items: center;
  padding: 8px 20px;
  height: 48px;
  background-color: ${({ color }) => color};
  border-radius: 10px;

  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  width: 344px;
  overflow-y: auto;
`;

const TextBox = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 88px;
  margin: 0 8px;
`;

const TextArea = styled.textarea`
  height: 100%;
  width: 90%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};
  color: ${({ theme }) => theme.colors.blueGray800};
  font-feature-settings: "liga" off, "clig" off;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;
  resize: none;
`;
