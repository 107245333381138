import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import DropDown from "@components/dropdown/DropDownButton";
import Search from "@pages/admin/imjang/application/components/Search";
import KanbanBoard from "./KanbanBoard";
import { useGetAuthorityUsers } from "@hooks/admin";
import { useImjang } from "@features/useImjang";
import FixedScrollTooltip from "./FixedScrollTooltip";

export default function ApplicantSearch() {
  const [selected, setSelected] = useState("");
  const { data: manager } = useGetAuthorityUsers({ code: "B03" });
  const { setGlobalSearchFilter } = useImjang();

  const list = useMemo(() => {
    if (!manager) return [];
    return manager?.map((m) => ({ title: m.name, value: m._id }));
  }, [manager]);

  useEffect(() => {
    setGlobalSearchFilter({ madminId: selected });
  }, [selected]);

  return (
    <>
      <Container>
        <h2>임장 비서 고객 관리</h2>
        <div className="searchBar">
          <DropDown
            optionList={[{ title: "담당자 전체", value: "" }, ...list]}
            selectedValue={selected}
            scrollHeight={200}
            onChangeValue={setSelected}
          />
          <div style={{ width: 8 }} />
          <Search />
        </div>
      </Container>
      <KanbanBoard />
      <FixedScrollTooltip />
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  h2 {
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.4px;
  }

  .searchBar {
    display: flex;
    margin-top: 10px;
  }
`;
